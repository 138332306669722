<template>
    <footer>
      <div class="bg-light py-4">
      <div class="container text-center">
        <p class="text-muted mb-0 py-2">Copyright © 2025 PT BUDGETIN TEKNOLOGI PROSPERO</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'AppFooter',
}
</script>

<style scoped>
body {
  background: #ff5f6d;
  background: -webkit-linear-gradient(to right, #ff5f6d, #ffc371);
  background: linear-gradient(to right, #ff5f6d, #ffc371);
  min-height: 100vh;
}

.text-muted {
  font-size: xx-small;
}

#button-addon1 {
  color: #ffc371;
}

i {
  color: #ffc371;
}

.form-control::placeholder {
  font-size: 0.95rem;
  color: #aaa;
  font-style: italic;
}

.form-control.shadow-0:focus {
  box-shadow: none;
}


</style>