<template>
    <div>
        ini halaman percobaan

    </div>
</template>

<script>

export default {
  name: 'ExportData',
  props: {},
  components: {

  },
  data() {
    return {
      raw16dr: ' ',
    }
  },
  metaInfo: {
    title: 'Budgetin - Export Data',
    meta: [
      {
        property: 'og:title',
        content: 'Export Data - Budgetin',
      },
    ],
  },
}
</script>