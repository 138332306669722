<template>
  <div class="content-list1-container thq-section-padding" style="padding-top: 0;">
    <div class="content-list1-max-width thq-section-max-width">
      <div class="content-list1-content thq-flex-column">
        <ul class="content-list1-ul thq-flex-column">
          <li class="thq-flex-column list-item">
            <h2 class="content-list1-heading1 thq-heading-2">
              <slot name="heading1">
                <!--Default content for heading1-->
                <div class="content-list1-fragment1">
                  <span class="content-list1-text1"></span>
                </div>
              </slot>
            </h2>
            <p class="thq-body-small">
              <slot name="content1">
                <!--Default content for content1-->
                <div class="content-list1-fragment4">
                  <span class="content-list1-text4">
                  </span>
                </div>
              </slot>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentList1',
  props: {},
}
</script>

<style scoped>
.content-list1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.content-list1-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
 
.content-list1-content {
  align-self: stretch;
  align-items: flex-start;
}
 
.content-list1-ul {
  align-items: flex-start;
}
 
.content-list1-heading1 {
  align-self: flex-start;
}
 
.content-list1-fragment1 {
  display: contents;
}
 
.content-list1-text1 {
  display: inline-block;
}
 
.content-list1-fragment2 {
  display: contents;
}
 
.content-list1-text2 {
  display: inline-block;
}
 
.content-list1-fragment3 {
  display: contents;
}
 
.content-list1-text3 {
  display: inline-block;
}
 
.content-list1-fragment4 {
  display: contents;
}
 
.content-list1-text4 {
  display: inline-block;
}
</style>
