<template>
  <div class="term-of-serivce-container">
    <app-navbar4>
    </app-navbar4>
    <app-features21
      feature1ImageSrc="/copy-writing-200h.png"
      feature2ImageSrc="/open-book-200h.png"
      feature3ImageSrc="/notification-200h.png"
      feature4ImageSrc="/hashtag-200h.png"
      feature5ImageSrc="/whatsapp1-200h.png"
    >
      <template v-slot:sectionTitle>
        <div class="home-fragment25">
          <span class="home-text115">Discover the Key Features</span>
        </div>
      </template>
      <template v-slot:feature1Title>
        <div class="home-fragment26">
          <span class="home-text116">Pencatatan Budget Simpel</span>
        </div>
      </template>
      <template v-slot:feature2Title>
        <div class="home-fragment26">
          <span class="home-text116">
            Ringkasan Budget
            <!-- <span v-html="rawybn1"></span> -->
          </span>
        </div>
      </template>
      <template v-slot:feature3Title>
        <div class="home-fragment28">
          <span class="home-text118">Pengingat Budget</span>
        </div>
      </template>
      <template v-slot:feature4Title>
        <div class="home-fragment29">
          <span class="home-text119">Kelola Catatan Budget dengan Tag</span>
        </div>
      </template>
      <template v-slot:feature5Title>
        <div class="home-fragment30">
          <span class="home-text120">Integrasi WhatsApp Sepenuhnya</span>
        </div>
      </template>
      <template v-slot:mainAction>
        <div class="home-fragment24">
          <RouterLink to="Term-of-Service"class="home-text114">Term of Service</RouterLink>
        </div>
      </template>
      <template v-slot:secondaryAction>
        <div class="home-fragment31">
          <RouterLink to="Privacy-Policy" class="home-text121">Privacy Policy</RouterLink>
        </div>
      </template>
      <template v-slot:sectionDescription>
        <div class="home-fragment32">
          <span class="home-text122">
            Budgetin AI adalah chatbot WhatsApp yang membantu kamu mencatat dan
            monitor budget. Ini dia beberapa fitur yang dapat membantumu dalam
            mengelola catatan budget sehari-hari.
          </span>
        </div>
      </template>
      <template v-slot:feature1Description>
        <div class="home-fragment33">
          <span class="home-text123">
            <span>Tambahkan catatan budget langsung lewat chat, </span>
            <span>
              Jurnal Budget dengan format sederhana
              <span v-html="rawjnfa"></span>
            </span>
            <span class="home-text126">
              (contoh: &quot;Makan siang 50K&quot; atau &quot;Terima gaji
              5Jt&quot;).
            </span>
            <br />
          </span>
        </div>
      </template>
      <template v-slot:feature2Description>
        <div class="home-fragment34">
          <span class="home-text128">
            Pantau ringkasan budget kamu secara mingguan atau bulanan untuk
            memahami pola pengeluaran dan pemasukan dengan lebih mudah.
          </span>
        </div>
      </template>
      <template v-slot:feature3Description>
        <div class="home-fragment35">
          <span class="home-text129">
            <span>
              Atur batas budget per kategori sesuai preferensi kamu
              <span v-html="raw8giy"></span>
            </span>
            <span class="home-text131">
              (contoh: &apos;Makan di luar maks. 1Jt/bulan&apos;).
            </span>
          </span>
        </div>
      </template>
      <template v-slot:feature4Description>
        <div class="home-fragment36">
          <span class="home-text132">
            <span>
              Gunakan tag untuk memudahkan pencatatan dan pengelompokan
              <span v-html="rawyvwb"></span>
            </span>
            <span class="home-text134">(contoh: #kerja, #liburan)</span>
            <span>.</span>
            <br />
            <span>Cari dan lihat ringkasan berdasarkan tag tertentu.</span>
          </span>
        </div>
      </template>
      <template v-slot:feature5Description>
        <div class="home-fragment37">
          <span class="home-text138">
            <span>Tanpa perlu aplikasi tambahan.</span>
            <br />
            <span>Cukup chat di WhatsApp, semua data tersimpan aman.</span>
            <br />
            <br />
            <span class="home-text144">
              Baca selengkapnya tentang Privacy Policy dan Term of Service di
              tombol bawah
            </span>
            <br class="home-text145" />
            <br />
          </span>
        </div>
      </template>
    </app-features21>
    <app-features24 feature1ImgSrc="/fitur%201-1400w.png" feature2ImgSrc="/fitur%201-1400w.png">
      <template v-slot:feature1Title>
        <div class="home-fragment38">
          <span class="home-text147">Cara Menggunakan Dan Berlangganan</span>
        </div>
      </template>
      <template v-slot:feature3Title>
        <div class="home-fragment39">
          <span class="home-text148">Berlangganan</span>
        </div>
      </template>
      <template v-slot:feature2Description>
        <div class="home-fragment40">
          <span class="home-text149">
            Anda hanya perlu menyapa Budgetin dengan halo, dan selanjutnya
            Budgetin akan memberikan arahan berupa pengenalan tentang apa itu
            Budgetin 
          </span>
        </div>
      </template>
      <template v-slot:feature3Description>
        <div class="home-fragment41">
          <span class="home-text150">
            Anda akan diberikan dua pilihan paket berlangganan yaitu bulanan atau
            tahunan dengan harga Rp 39.900 untuk bulanan dan Rp 299.000 untuk
            tahunan. 
          </span>
        </div>
      </template>
    </app-features24>
    <app-features25 feature1ImgSrc="/fitur2-1400w.png">
      <template v-slot:feature1Title>
        <div class="home-fragment42">
          <span class="home-text151">Pembayaran</span>
        </div>
      </template>
      <template v-slot:feature2Title>
        <div class="home-fragment43">
          <span class="home-text152">Gunakan Budgetin</span>
        </div>
      </template>
      <template v-slot:feature1Description>
        <div class="home-fragment44">
          <span class="home-text153">
            Setelah memilih paket dengan command !tahunan atau !bulanan, anda akan
            diarahkan untuk melakukan pembayaran dengan mengklik link pembayaran
            agar bisa mengaktifkan langganan
          </span>
        </div>
      </template>
      <template v-slot:feature2Description>
        <div class="home-fragment45">
          <span class="home-text154">
            Setelah pembayaran berhasil, Anda dapat langsung menggunakan Budgetin
            untuk mencatat pengeluaran, menuliskan jurnal budget harian, melihat
            ringkasan pemasukan dan pengeluaran, serta mengelompokkan catatan
            sesuai kategori.
          </span>
        </div>
      </template>
      <template v-slot:feature3Description>
        <div class="home-fragment46">
          <span class="home-text155">
            <span>
              Jika mengalami masalah atau membutuhkan bantuan, hubungi kami di
              <span v-html="rawqt60"></span>
            </span>
            <span class="home-text157">admin@budget-in.com</span>
            <span>
              atau
              <span v-html="rawi0q0"></span>
            </span>
            <br />
            <span>
              Whatsapp Budgetin Support
              <span v-html="rawnn34"></span>
            </span>
            <span class="home-text161">+62 81387759975</span>
          </span>
        </div>
      </template>
    </app-features25>
    <AppFooter>

    </AppFooter>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures21 from '../components/features21'
import AppFeatures24 from '../components/features24'
import AppFeatures25 from '../components/features25'
import AppFooter from '../components/AppFooter.vue';
import { RouterLink } from 'vue-router';

export default {
  name: 'Berlangganan',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures21,
    AppFeatures24,
    AppFeatures25,
    AppFooter
  },
  data() {
    return {
      raw16dr: ' ',
    }
  },
  metaInfo: {
    title: 'Budgetin - Berlangganan',
    meta: [
      {
        property: 'og:title',
        content: 'Berlangganan - Budgetin',
      },
    ],
  },
}
</script>

<style scoped>
.term-of-serivce-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.term-of-serivce-fragment10 {
  display: contents;
}
 
.term-of-serivce-text100 {
  display: inline-block;
}
 
.term-of-serivce-fragment11 {
  display: contents;
}
 
.term-of-serivce-text101 {
  display: inline-block;
}
 
.term-of-serivce-fragment12 {
  display: contents;
}
 
.term-of-serivce-text102 {
  display: inline-block;
}
 
.term-of-serivce-fragment13 {
  display: contents;
}
 
.term-of-serivce-text103 {
  display: inline-block;
}
 
.term-of-serivce-fragment14 {
  display: contents;
}
 
.term-of-serivce-text104 {
  display: inline-block;
}
 
.term-of-serivce-fragment15 {
  display: contents;
}
 
.term-of-serivce-text105 {
  display: inline-block;
}
 
.term-of-serivce-fragment16 {
  display: contents;
}
 
.term-of-serivce-text106 {
  display: inline-block;
}
 
.term-of-serivce-fragment17 {
  display: contents;
}
 
.term-of-serivce-text107 {
  display: inline-block;
}
 
.term-of-serivce-fragment18 {
  display: contents;
}
 
.term-of-serivce-text108 {
  display: inline-block;
}
 
.term-of-serivce-fragment19 {
  display: contents;
}
 
.term-of-serivce-text109 {
  display: inline-block;
}
 
.term-of-serivce-fragment20 {
  display: contents;
}
 
.term-of-serivce-text110 {
  display: inline-block;
}
 
.term-of-serivce-fragment21 {
  display: contents;
}
 
.term-of-serivce-text111 {
  display: inline-block;
}
 
.term-of-serivce-fragment22 {
  display: contents;
}
 
.term-of-serivce-text112 {
  display: inline-block;
}
 
.term-of-serivce-fragment23 {
  display: contents;
}
 
.term-of-serivce-text113 {
  display: inline-block;
}
 
.term-of-serivce-fragment24 {
  display: contents;
}
 
.term-of-serivce-text114 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text115 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text119 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text127 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text128 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text133 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text143 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text147 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text153 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text157 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text161 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text165 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text169 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text173 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text174 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text181 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-fragment25 {
  display: contents;
}
 
.term-of-serivce-text187 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text188 {
  font-weight: 700;
}
 
.term-of-serivce-text200 {
  font-weight: 700;
}
 
.term-of-serivce-text212 {
  font-weight: 700;
}
 
.term-of-serivce-text218 {
  font-weight: 700;
}
 
.term-of-serivce-text228 {
  font-weight: 700;
}
 
.term-of-serivce-text240 {
  font-weight: 700;
}
 
.term-of-serivce-text244 {
  font-weight: 700;
}
 
.term-of-serivce-text245 {
  font-weight: 700;
}
 
.term-of-serivce-text254 {
  font-weight: 700;
}
 
.term-of-serivce-text261 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-text264 {
  font-weight: 700;
}
 
.term-of-serivce-fragment26 {
  display: contents;
}
 
.term-of-serivce-text267 {
  display: inline-block;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 800;
}
 
.term-of-serivce-fragment27 {
  display: contents;
}
 
.term-of-serivce-text268 {
  display: inline-block;
  font-size: 36px;
  font-family: "Open Sans";
}

/*
COPY FROM BERLANGGANAN
*/

.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-fragment10 {
  display: contents;
}
 
.home-text100 {
  display: inline-block;
}
 
.home-fragment11 {
  display: contents;
}
 
.home-text101 {
  display: inline-block;
}
 
.home-fragment12 {
  display: contents;
}
 
.home-text102 {
  display: inline-block;
}
 
.home-fragment13 {
  display: contents;
}
 
.home-text103 {
  display: inline-block;
}
 
.home-fragment14 {
  display: contents;
}
 
.home-text104 {
  display: inline-block;
}
 
.home-fragment15 {
  display: contents;
}
 
.home-text105 {
  display: inline-block;
}
 
.home-fragment16 {
  display: contents;
}
 
.home-text106 {
  display: inline-block;
}
 
.home-fragment17 {
  display: contents;
}
 
.home-text107 {
  display: inline-block;
}
 
.home-fragment18 {
  display: contents;
}
 
.home-text108 {
  display: inline-block;
}
 
.home-fragment19 {
  display: contents;
}
 
.home-text109 {
  display: inline-block;
}
 
.home-fragment20 {
  display: contents;
}
 
.home-text110 {
  display: inline-block;
}
 
.home-fragment21 {
  display: contents;
}
 
.home-text111 {
  display: inline-block;
}
 
.home-fragment22 {
  display: contents;
}
 
.home-text112 {
  display: inline-block;
}
 
.home-fragment23 {
  display: contents;
}
 
.home-text113 {
  display: inline-block;
}
 
.home-fragment24 {
  display: contents;
}
 
.home-text114 {
  display: inline-block;
}
 
.home-fragment25 {
  display: contents;
}
 
.home-text115 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.home-fragment26 {
  display: contents;
  text-align: left;
}
 
.home-text116 {
  display: inline-block;
  text-align: left;
}
 
.home-fragment27 {
  display: contents;
}
 
.home-text117 {
  display: inline-block;
}
 
.home-fragment28 {
  display: contents;
}
 
.home-text118 {
  display: inline-block;
}
 
.home-fragment29 {
  display: contents;
}
 
.home-text119 {
  display: inline-block;
}
 
.home-fragment30 {
  display: contents;
}
 
.home-text120 {
  display: inline-block;
}
 
.home-fragment31 {
  display: contents;
}
 
.home-text121 {
  display: inline-block;
}
 
.home-fragment32 {
  display: contents;
}
 
.home-text122 {
  display: inline-block;
}
 
.home-fragment33 {
  display: contents;
}
 
.home-text123 {
  display: inline-block;
}
 
.home-text126 {
  font-style: italic;
  font-weight: 400;
}
 
.home-fragment34 {
  display: contents;
}
 
.home-text128 {
  display: inline-block;
}
 
.home-fragment35 {
  display: contents;
}
 
.home-text129 {
  display: inline-block;
}
 
.home-text131 {
  font-style: italic;
  font-weight: 400;
}
 
.home-fragment36 {
  display: contents;
}
 
.home-text132 {
  display: inline-block;
}
 
.home-text134 {
  font-style: italic;
  font-weight: 400;
}
 
.home-fragment37 {
  display: contents;
}
 
.home-text138 {
  display: inline-block;
}
 
.home-text144 {
  font-style: italic;
  font-weight: 600;
}
 
.home-text145 {
  font-style: italic;
  font-weight: 600;
}
 
.home-fragment38 {
  display: contents;
}
 
.home-text147 {
  display: inline-block;
}
 
.home-fragment39 {
  display: contents;
}
 
.home-text148 {
  display: inline-block;
}
 
.home-fragment40 {
  display: contents;
}
 
.home-text149 {
  display: inline-block;
}
 
.home-fragment41 {
  display: contents;
}
 
.home-text150 {
  display: inline-block;
}
 
.home-fragment42 {
  display: contents;
}
 
.home-text151 {
  display: inline-block;
}
 
.home-fragment43 {
  display: contents;
}
 
.home-text152 {
  display: inline-block;
}
 
.home-fragment44 {
  display: contents;
}
 
.home-text153 {
  display: inline-block;
}
 
.home-fragment45 {
  display: contents;
}
 
.home-text154 {
  display: inline-block;
}
 
.home-fragment46 {
  display: contents;
}
 
.home-text155 {
  display: inline-block;
}
 
.home-text157 {
  font-style: normal;
  font-weight: 700;
}
 
.home-text161 {
  font-style: normal;
  font-weight: 700;
}
 
.home-fragment47 {
  display: contents;
}
 
.home-text162 {
  display: inline-block;
}
 
.home-text163 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text164 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text168 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text171 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text172 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text177 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text180 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text181 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text185 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text188 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text189 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text193 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text196 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text197 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text201 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text204 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text205 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text209 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text212 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text213 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text217 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text220 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text221 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text225 {
  font-style: italic;
  font-weight: 400;
}
 
.home-text228 {
  font-style: normal;
  font-weight: 800;
}
 
.home-text229 {
  font-style: italic;
  font-weight: 800;
}
 
.home-text232 {
  font-style: normal;
  font-weight: 700;
}
 
.home-text234 {
  font-style: normal;
  font-weight: 700;
}
 
.home-text236 {
  font-style: normal;
  font-weight: 700;
}
 
.home-text239 {
  font-style: italic;
  font-weight: 400;
}
 
.home-fragment48 {
  display: contents;
}
 
.home-text242 {
  display: inline-block;
}
</style>

