<template>
  <header class="navbar4-container">
    <header data-thq="thq-navbar" class="navbar4-navbar-interactive">
      <img :alt="logoAlt" :src="logoSrc" class="navbar4-image1" />
      <div data-thq="thq-navbar-nav" class="navbar4-desktop-menu">
        <nav class="navbar4-links1">
          <RouterLink to="/" class="thq-body-small thq-link">
            <slot name="link1">
              <div class="term-of-serivce-fragment10">
                <span class="term-of-serivce-text100">Home</span>
              </div>
            </slot>
          </RouterLink>
          <routerLink to="Berlangganan" class="thq-body-small thq-link">
            <slot name="link2">
              <div class="term-of-serivce-fragment11">
                <span class="term-of-serivce-text101">Berlangganan</span>
              </div>
            </slot>
          </routerLink>
          <routerLink to="Term-of-Service" class="thq-body-small thq-link">
            <slot name="link3">
              <div class="term-of-serivce-fragment12">
                <span class="term-of-serivce-text102">Term of Service</span>
              </div>
            </slot>
          </routerLink>
          <routerLink to="Privacy-Policy" class="thq-body-small thq-link">
            <slot name="link4">
              <div class="term-of-serivce-fragment13">
                <span class="term-of-serivce-text103">Privacy Policy</span>
              </div>
            </slot>
          </routerLink>
          <routerLink to="FAQ" class="thq-body-small thq-link">
            <slot name="link5">
              <div class="term-of-serivce-fragment14">
                <span class="term-of-serivce-text104">FAQ</span>
              </div>
            </slot>
          </routerLink>
        </nav>
        <div class="navbar4-buttons1">
          <button class="navbar4-action11 thq-button-animated thq-button-filled" @click="openWhatsApp">
            <span>
              <slot name="action1">
                <div class="term-of-serivce-fragment15">
                  <span class="term-of-serivce-text105">Gunakan</span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
      <div data-thq="thq-burger-menu" class="navbar4-burger-menu">
        <svg viewBox="0 0 1024 1024" class="navbar4-icon1">
          <path
            d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z">
          </path>
        </svg>
      </div>
      <div data-thq="thq-mobile-menu" class="navbar4-mobile-menu">
        <div class="navbar4-nav">
          <div class="navbar4-top">
            <img :alt="logoAlt" :src="logoSrc" class="navbar4-logo" />
            <div data-thq="thq-close-menu" class="navbar4-close-menu">
              <svg viewBox="0 0 1024 1024" class="navbar4-icon3">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z">
                </path>
              </svg>
            </div>
          </div>
          <nav class="navbar4-links2">
            <routerLink to="/" class="thq-body-small thq-link">
              <slot name="link1">
                <div class="term-of-serivce-fragment10">
                  <span class="term-of-serivce-text100">Home</span>
                </div>
              </slot>
            </routerLink>
            <routerLink to="Berlangganan" class="thq-body-small thq-link">
              <slot name="link2">
                <div class="term-of-serivce-fragment11">
                  <span class="term-of-serivce-text101">Berlangganan</span>
                </div>
              </slot>
            </routerLink>
            <routerLink to="Term-of-Service" class="thq-body-small thq-link">
              <slot name="link3">
                <div class="term-of-serivce-fragment12">
                  <span class="term-of-serivce-text102">Term of Service</span>
                </div>
              </slot>
            </routerLink>
            <routerLink to="Privacy-Policy" class="thq-body-small thq-link">
              <slot name="link4">
                <div class="term-of-serivce-fragment13">
                  <span class="term-of-serivce-text103">Privacy Policy</span>
                </div>
              </slot>
            </routerLink>
            <routerLink to="FAQ" class="thq-body-small thq-link">
              <slot name="link5">
                <div class="term-of-serivce-fragment14">
                  <span class="term-of-serivce-text104">FAQ</span>
                </div>
              </slot>
            </routerLink>
          </nav>
        </div>
      </div>
    </header>
  </header>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  name: 'Navbar4',
  props: {
    link5Url: {
      type: String,
      default: `${window.location.origin}/Home`,
    },
    link4Url: {
      type: String,
      default: '',
    },
    link1Url: {
      type: String,
      default: `${window.location.origin}/`,
    },
    logoSrc: {
      type: String,
      default:
        "/budgetin_logo-1500h.png"
    },
    link3Url: {
      type: String,
      default: `${window.location.origin}/privacy-policy`
    },
    link2Url: {
      type: String,
      default: `${window.location.origin}/term-of-service`,
    },
    logoAlt: {
      type: String,
      default: 'logo',
    },
    whatsappUrl: {
      type: String,
      default: 'https://wa.me/message/RSVN7UVO37DKF1',
    },

  },
  methods: {
    openWhatsApp() {
      window.open(this.whatsappUrl, "_blank"); // Opens WhatsApp link in a new tab
    },
  },
}
</script>

<style scoped>
.navbar4-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar4-navbar-interactive {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}

.navbar4-image1 {
  height: 3rem;
}

.navbar4-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.navbar4-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
  flex-direction: row;
  justify-content: flex-start;
}

.navbar4-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-twounits);
}

.navbar4-action11 {
  display: flex;
  flex-direction: row;
}

.navbar4-burger-menu {
  display: none;
}

.navbar4-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar4-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  background-color: var(--dl-color-theme-neutral-light);
}

.navbar4-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar4-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar4-logo {
  height: 3rem;
}

.navbar4-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar4-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar4-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.navbar4-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
}

.navbar4-fragment1 {
  display: contents;
}

.navbar4-text2 {
  display: inline-block;
}

.navbar4-fragment2 {
  display: contents;
}

.navbar4-text3 {
  display: inline-block;
}

.navbar4-fragment3 {
  display: contents;
}

.navbar4-text4 {
  display: inline-block;
}

.navbar4-fragment4 {
  display: contents;
}

.navbar4-text5 {
  display: inline-block;
}

.navbar4-fragment5 {
  display: contents;
}

.navbar4-text6 {
  display: inline-block;
}

.navbar4-fragment6 {
  display: contents;
}

.navbar4-text7 {
  display: inline-block;
}

@media(max-width: 767px) {
  .navbar4-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .navbar4-desktop-menu {
    display: none;
  }

  .navbar4-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media(max-width: 479px) {
  .navbar4-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }

  .navbar4-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
</style>
