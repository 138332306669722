<template>
  <div class="term-of-serivce-container">
    <app-navbar4>
    </app-navbar4>
    <content-list1>
      
      <template v-slot:heading1>
        <div class="term-of-serivce-fragment26">
          <span class="term-of-serivce-text267">Term of Service</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="term-of-serivce-fragment24">
          <span class="term-of-serivce-text114">
            <span class="term-of-serivce-text115">Acceptance of Terms</span>
            <br />
            <span>
              By using Budgetin, you acknowledge that you have read, understood, and agreed to these Terms and the Privacy Policy.
              You also accept and comply with WhatsApp's Terms of Service and Meta's Privacy Policy when using our services through the WhatsApp platform.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">1. Introduction</span>
            <br />
            <span>
              Budgetin is a WhatsApp based personal budget journaling AI Bot designed to assist users with managing their budgets, including budgeting, and expense monitoring. 
              <b>By using Budgetin, you agree to these Terms of Service.</b>
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">2. Eligibility</span>
            <br />
            <span>To use Budgetin, you must:</span>
            <br />
            <span>• Be at least 13+ years old.</span>
            <br />
            <span>• Comply with applicable laws and regulations.</span>
            <br />
            <span>• Use the service in accordance with <b>Meta's Terms of Service and Privacy Policy.</b></span>
            <br />
            <br />
            <span class="term-of-serivce-text115">3. User Responsibilities</span>
            <br />
            <span class="term-of-serivce-text127">
              User Responsibilites
            </span>
            <br />
            <span>Users are responsible for:</span>
            <br />
            <span>• Providing <b>budgeting details</b> at their own discretion.</span>
            <br />
            <span>
              • Maintaining the confidentiality of their interactions with the Bot.
            </span>
            <br />
            <span>• Avoiding unauthorized sharing of account access.</span>
            <br />
            <span class="term-of-serivce-text127">
              User-Submitted Budget Details
            </span>
            <br />
            <span>
              Users may input <b>budget-related information</b> to utilize Budgetin's services. 
              However, Budgetin <b>never requires or compels users to provide personal financial reports or any sensitive data.</b> 
              All submitted data is used solely for <b>personal budget organization,</b> in accordance with our Privacy Policy.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">4. Intellectual Property</span>
            <br />
            <span>
              All content, algorithms, trademarks, and intellectual property associated with Budgetin remain the sole property of <b>PT BUDGETIN TEKNOLOGI PROSPERO. </b>
              Unauthorized use, reproduction, distribution, or modification of any Budgetin assets without prior written consent is strictly prohibited.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">5. Disclaimer</span>
            <br />
            <span>
              • Budgetin is a <b>budget journaling tool</b> designed to help users log and organize their budgeting details. It <b>does not provide financial, legal, or investment advice.</b>
            </span>
            <br />
            <span>
              • The service does not analyze, predict, or validate any budget-related information entered by users.
            </span>
            <br />
            <span>
              • Users remain solely responsible for their budget management decisions. 
              Budgetin <b>bears no liability for any outcomes, losses, or decisions made based on information recorded within the service.</b>
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">6. Termination</span>
            <br />
            <span>
              Budgetin reserves the right to <b>suspend or terminate</b> access to the Bot in cases of:
            </span>
            <br />
            <span>
              •	Violations of these Terms.
            </span>
            <br />
            <span>
              •	Suspected misuse or fraudulent activity.
            </span>
            <br />
            <span>
              •	Compliance with legal obligations.
            </span>
            <br />
            <span>
              Whenever possible, we will provide <b>prior notice</b> before taking such actions.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">7. Governing Law</span>
            <br />
            <span>These Terms are governed by and interpreted in accordance with the <b>laws of Indonesia.</b> Any disputes arising from these Terms shall be resolved through the applicable legal process within Indonesian jurisdiction.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">8. Indemnification</span>
            <br />
            <span>
              You agree to indemnify and hold <b>Budgetin and its affiliates harmless</b> from any claims, damages, liabilities, or expenses arising from:
            </span>
            <br />
            <span>
              •	Your misuse of the Bot.
            </span>
            <br />
            <span>
              •	Your violation of these Terms.
            </span>
            <br />
            <span>
              •	Any legal consequences resulting from your financial decisions based on the Bot’s output.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">
              9. Limitation of Liability
            </span>
            <br />
            <span>
              Budgetin's liability is limited <b>to the extent permitted by applicable law</b> and shall not exceed the <b>total amount of fees paid by the user for the services provided.</b>
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">10. Data Access & Export for Budget Journaling</span>
            <br />
            <span><b>Eligibility for Data Export</b></span>
            <br />
            <span>• Users can export their budget journaling data through the dedicated export feature on the Budgetin website, <b>provided they complete the verification process</b>.</span>
            <br />
            <span>• Only users with a registered phone number in our database can proceed with the data export request.</span>
            <br />
            <span><b>Verification Process & Security</b></span>
            <br />
            <span>• To ensure security, users must first pass a CAPTCHA verification before accessing the export feature.</span>
            <br />
            <span>• Users must enter their registered phone number. If the number exists in our database, an OTP (One-Time Password) will be sent via SMS for verification.</span>
            <br />
            <span>• If the entered phone number is not found in our system, no OTP will be sent, and the export request cannot proceed.</span>
            <br />
            <span><b>Data Export Format & Delivery</b></span>
            <br />
            <span>• Upon successful verification, users must provide:</span>
            <br />
            <span>&emsp;- A valid email address, which will be used solely to grant access to the exported data via Google Sheets.</span>
            <br />
            <span>&emsp;- A specified date range for the data export, determining the period of budget journaling data to be included.</span>
            <br />
            <span>• The exported data will be formatted in accordance with Budgetin’s predefined template and delivered via Google Sheets.</span>
            <br />
            <span>• Users will receive a notification upon completion of the export process, enabling them to access and download their data.</span>
            <br />
            <span><b>Service Limitations & Disclaimer</b></span>
            <br />
            <span>• The data export feature is provided “as-is” and may require processing time before completion.</span>
            <br />
            <span>• Budgetin does not modify, analyze, or store exported data beyond its structured export process.</span>
            <br />
            <span>• Budgetin does not retain access to Google Sheets once the data has been delivered. Users are responsible for managing and securing their exported data.</span>
            <br />
            <span>• Budgetin is not responsible for any data loss, sharing, or breaches that occur after the export has been successfully completed and transferred to the user.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">11. Amendments</span>
            <br />
            <span>
              These Terms may be updated periodically to reflect:
            </span>
            <br />
            <span>
              •	Service improvements.
            </span>
            <br />
            <span>
              •	Legal and regulatory changes.
            </span>
            <br />
            <span>
              •	Policy updates to comply with platform guidelines.
            </span>
            <br />
            <span>
              Users will be notified of significant changes via <b>WhatsApp notifications or email.</b> 
              Continued use of Budgetin after such updates constitutes <b>acceptance of the revised Terms.</b>
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">
              12. WhatsApp-Specific Terms
            </span>
            <br />
            <span>
              <b>Budgetin operates in full compliance with <b>WhatsApp Business Policy</b> and <b>Meta's Platform Guidelines,</b> ensuring transparency and user control over data.</b>
            </span>
            <br />
            <span>
              • Users <b>must provide explicit consent</b> before any budget-related data is logged or stored.
            </span>
            <br />
            <span>
              • Clear and accessible instructions are provided for users to <b>request data deletion</b> or opt-out at any time.
            </span>
            <br />
            <span>
              • Budgetin does not share user data with third parties and follows WhatsApp's requirements for <b>data security and privacy.</b>
            </span>
            <br />
            <span>
              • Data handling is <b>strictly limited to user-initiated interactions,</b> without any automated data collection beyond what users voluntarily provide.
            </span>
          </span>
        </div>
      </template>
    </content-list1>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures5 from '../components/features5'
import ContentList1 from '../components/content-list1'
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'TermOfSerivce',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures5,
    ContentList1,
    AppFooter
  },
  data() {
    return {
      raw16dr: ' ',
    }
  },
  metaInfo: {
    title: 'Budgetin - Term of Service',
    meta: [
      {
        property: 'og:title',
        content: 'Term-of-Service - Budgetin',
      },
    ],
  },
}
</script>

<style scoped>
.term-of-serivce-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.term-of-serivce-fragment10 {
  display: contents;
}
 
.term-of-serivce-text100 {
  display: inline-block;
}
 
.term-of-serivce-fragment11 {
  display: contents;
}
 
.term-of-serivce-text101 {
  display: inline-block;
}
 
.term-of-serivce-fragment12 {
  display: contents;
}
 
.term-of-serivce-text102 {
  display: inline-block;
}
 
.term-of-serivce-fragment13 {
  display: contents;
}
 
.term-of-serivce-text103 {
  display: inline-block;
}
 
.term-of-serivce-fragment14 {
  display: contents;
}
 
.term-of-serivce-text104 {
  display: inline-block;
}
 
.term-of-serivce-fragment15 {
  display: contents;
}
 
.term-of-serivce-text105 {
  display: inline-block;
}
 
.term-of-serivce-fragment16 {
  display: contents;
}
 
.term-of-serivce-text106 {
  display: inline-block;
}
 
.term-of-serivce-fragment17 {
  display: contents;
}
 
.term-of-serivce-text107 {
  display: inline-block;
}
 
.term-of-serivce-fragment18 {
  display: contents;
}
 
.term-of-serivce-text108 {
  display: inline-block;
}
 
.term-of-serivce-fragment19 {
  display: contents;
}
 
.term-of-serivce-text109 {
  display: inline-block;
}
 
.term-of-serivce-fragment20 {
  display: contents;
}
 
.term-of-serivce-text110 {
  display: inline-block;
}
 
.term-of-serivce-fragment21 {
  display: contents;
}
 
.term-of-serivce-text111 {
  display: inline-block;
}
 
.term-of-serivce-fragment22 {
  display: contents;
}
 
.term-of-serivce-text112 {
  display: inline-block;
}
 
.term-of-serivce-fragment23 {
  display: contents;
}
 
.term-of-serivce-text113 {
  display: inline-block;
}
 
.term-of-serivce-fragment24 {
  display: contents;
}
 
.term-of-serivce-text114 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text115 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text119 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text127 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text128 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text133 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text143 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text147 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text153 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text157 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text161 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text165 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text169 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text173 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text174 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text181 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-fragment25 {
  display: contents;
}
 
.term-of-serivce-text187 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text188 {
  font-weight: 700;
}
 
.term-of-serivce-text200 {
  font-weight: 700;
}
 
.term-of-serivce-text212 {
  font-weight: 700;
}
 
.term-of-serivce-text218 {
  font-weight: 700;
}
 
.term-of-serivce-text228 {
  font-weight: 700;
}
 
.term-of-serivce-text240 {
  font-weight: 700;
}
 
.term-of-serivce-text244 {
  font-weight: 700;
}
 
.term-of-serivce-text245 {
  font-weight: 700;
}
 
.term-of-serivce-text254 {
  font-weight: 700;
}
 
.term-of-serivce-text261 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-text264 {
  font-weight: 700;
}
 
.term-of-serivce-fragment26 {
  display: contents;
}
 
.term-of-serivce-text267 {
  display: inline-block;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 800;
}
 
.term-of-serivce-fragment27 {
  display: contents;
}
 
.term-of-serivce-text268 {
  display: inline-block;
  font-size: 36px;
  font-family: "Open Sans";
}
</style>
