<template>
  <div class="term-of-serivce-container">
    <app-navbar4>
    </app-navbar4>
    <content-list1>
      
      <template v-slot:heading1>
        <div class="term-of-serivce-fragment26">
          <span class="term-of-serivce-text267">Privacy Policy</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="term-of-serivce-fragment24">
          <span class="term-of-serivce-text114">
            <span class="term-of-serivce-text115">Acceptance of Terms</span>
            <br />
            <span>
              By using Budgetin, you acknowledge that you have read, understood, and agreed to these Terms and the Privacy Policy.
              You also accept and comply with WhatsApp's Terms of Service and Meta's Privacy Policy when using our services through the WhatsApp platform.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">1. Data Collection</span>
            <br />
            <span>
              We only collect data that users voluntarily provide without coercion or specific direction. 
              All recorded information is entirely based on the input users enter into Budgetin. 
              We do not automatically retrieve or process data beyond what users intentionally submit.
              <br />The types of data collected include:
            </span>
            <br />
            <span>
              • <b>User-Submitted Budget Details: </b> Includes income, expenses, and budgets that you voluntarily input. We do not require or compel you to provide any specific financial details.
            </span>
            <br />
            <span>
              • <b>User Interactions:</b> Messages, queries, responses, and feedback shared with the bot.
            </span>
            <br />
            <span>
              We do not collect device information. However, WhatsApp and Meta may collect device-related data as part of their platform policies. Please refer to <a href="https://www.facebook.com/privacy/policy" target="_blank" style="color: black; font: bold"><b>Meta's Privacy Policy</b></a> & <a href="https://business.whatsapp.com/policy" target="_blank" style="color: black; font: bold"><b>Whatsapp Business Privacy Policy</b></a> for more details
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">2. Data Use</span>
            <br />
            <span>Your data is used solely for the following purposes:</span>
            <br />
            <span>
              • Helping you monitor and summarize <b>your budget and expense details</b> based on the information you voluntarily provide.
            </span>
            <br />
            <span>• Improving the bot's functionality to enhance user experience.</span>
            <br />
            <span>• Providing structured overviews and user-initiated reminders for your Budget journaling.</span>
            <br />
            <span>• Ensuring compliance with applicable legal and regulatory requirements.</span>
            <br />
            <span><b>Important Notice:</b> Budgetin does not provide any financial, investment, or legal advice. 
              The service is solely intended for personal budget journaling and monitoring.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">3. Data Sharing</span>
            <br />
            <span>
              • <b>Third-Party Services:</b> We do not share your data with third parties, except when required for compliance with legal obligations.
            </span>
            <br />
            <span>
              • <b>Legal Compliance:</b> Data will only be disclosed when required by law or regulatory authorities.
            </span>
            <br />
            <span>
              • <b>Internal Use:</b> Your data is processed securely and only used to enhance service performance. 
              We do not access or review your data content unless explicitly authorized by you for troubleshooting or support purposes.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">4. Data Security</span>
            <br />
            <span>
              We implement <b>strict security measures</b> to safeguard your information:
            </span>
            <br />
            <span>• <b>Encryption:</b> All user-provided data is securely encrypted to maintain confidentiality.</span>
            <br />
            <span>• <b>Access Control:</b> Data access is strictly limited to <b>authorized systems and automated processes</b>, no individual has direct access.</span>
            <br />
            <span>• <b>Security Audits:</b> We conduct <b>regular security assessments</b> to ensure compliance with industry standards and platform requirements.</span>
            <br />
            <span>• <b>Data Protection Policies:</b> Our security protocols align with best practices to prevent unauthorized access, data breaches, or misuse.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">5. User Rights</span>
            <br />
            <span>You have full control over your data, including the right to:</span>
            <br />
            <span>• <b>Access:</b> Request a copy of your data at any time.</span>
            <br />
            <span>• <b>Correction:</b> Modify or update any inaccurate information.</span>
            <br />
            <span>
              • <b>Deletion:</b> Request deletion of your account and all associated data by visiting <a href="https://budget-in.com/delete-data" target="_blank" style="font: bold"><b> budget-in.com/delete-data</b></a>
            </span>
            <br />
            <span>
              • <b>Opt-Out:</b> Manage preferences for receiving communications.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">6. Data Retention</span>
            <br />
            <span>
              • We retain user-provided budget journal for up to 12 months to improve service functionality, unless you request deletion earlier.
            </span>
            <br />
            <span>
              • After this period, data is securely erased unless required for compliance purposes.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">7. Compliance</span>
            <br />
            <span>Budgetin adheres to:</span>
            <br />
            <span>• <b>Applicable local regulations in Indonesia,</b> including but not limited to relevant consumer protection and data privacy laws.</span>
            <br />
            <span>• <b>WhatsApp Business Policy and Guidelines,</b> WhatsApp Business Policy and Guidelines. </span>
            <br />
            <span>• <b>Meta's Platform Terms and Developer Policies,</b> including compliance with data handling and user consent requirements. </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">8. User Data Protection & Export Policy for Budget Journaling</span>
            <br />
            <span><b>Data Collection & Storage</b></span>
            <br />
            <span>• Budgetin securely stores user-submitted budget journaling data in compliance with our Privacy Policy.</span>
            <br />
            <span>•	We do not share your data with third parties, except when required to comply with <b>legal obligations or regulatory requirements.</b></span>
            <br />
            <span>• Data is stored securely and used solely for providing Budgetin’s budget journaling services. We do not process or analyze user data beyond what is necessary for service functionality. </span>
            <br />
            <span><b>User Data Export & Access</b>
            </span>
            <br />
            <span>• Users may request an export of their budget journaling data using Budgetin’s secure export feature.</span>
            <br />
            <span>•	Only verified users (through an OTP-based process) can access and download their data. </span>
            <br />
            <span>• Budgetin does not retain access to exported data once it has been delivered. </span>
            <br />
            <span><b>Security & Verification</b>
            </span>
            <br />
            <span>• To prevent unauthorized access, users must complete: </span>
            <br />
            <span>&emsp;- CAPTCHA verification before initiating the export request. </span>
            <br />
            <span>&emsp;-	OTP (One-Time Password) verification via SMS for authentication. </span>
            <br />
            <span>•	If a user’s phone number is not found in our database, no OTP will be sent, and the export request will be denied. </span>
            <br />
            <span><b>Data Processing & Privacy</b>
            </span>
            <br />
            <span>• Exported data is formatted into a predefined structured template and delivered to the user’s specified Google Sheets.</span>
            <br />
            <span>•	Budgetin does not modify, analyze, or process user data beyond its structured export. </span>
            <br />
            <span>• Users are responsible for managing and securing their exported data after it has been successfully transferred. </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">9. Additional Disclosures</span>
            <br />
            <span>
              • <b>Data Breach Notification,</b> In the event of a data breach affecting your information, we will notify affected users within <b>72 hours</b>, in accordance with applicable regulations.
            </span>
            <br />
            <span>
              • <b>Data Storage & Cross-Border Processing,</b> Your data is securely stored and processed on <b>Google Cloud Platform (GCP)</b> servers, which may be located internationally. 
              We ensure that all transfers comply with <b>applicable data protection laws</b> and adhere to <b>strict security protocols</b> to safeguard your information.
            </span>
            <br />
            <br />
            <br />
            <span class="term-of-serivce-text261">Contact Us</span>
            <br />
            <span>
              If you have questions or concerns about your data, please contact us
              at
              <span v-html="raw16dr"></span>
            </span>
            <span class="term-of-serivce-text264">admin@budget-in.com</span>
          </span>
        </div>
      </template>
    </content-list1>
    <AppFooter>

    </AppFooter>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures5 from '../components/features5'
import ContentList1 from '../components/content-list1'
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'TermOfSerivce',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures5,
    ContentList1,
    AppFooter
  },
  data() {
    return {
      raw16dr: ' ',
    }
  },
  metaInfo: {
    title: 'Budgetin - Privacy Policy',
    meta: [
      {
        property: 'og:title',
        content: 'Privacy-Policy - Budgetin',
      },
    ],
  },
}
</script>

<style scoped>
.term-of-serivce-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.term-of-serivce-fragment10 {
  display: contents;
}
 
.term-of-serivce-text100 {
  display: inline-block;
}
 
.term-of-serivce-fragment11 {
  display: contents;
}
 
.term-of-serivce-text101 {
  display: inline-block;
}
 
.term-of-serivce-fragment12 {
  display: contents;
}
 
.term-of-serivce-text102 {
  display: inline-block;
}
 
.term-of-serivce-fragment13 {
  display: contents;
}
 
.term-of-serivce-text103 {
  display: inline-block;
}
 
.term-of-serivce-fragment14 {
  display: contents;
}
 
.term-of-serivce-text104 {
  display: inline-block;
}
 
.term-of-serivce-fragment15 {
  display: contents;
}
 
.term-of-serivce-text105 {
  display: inline-block;
}
 
.term-of-serivce-fragment16 {
  display: contents;
}
 
.term-of-serivce-text106 {
  display: inline-block;
}
 
.term-of-serivce-fragment17 {
  display: contents;
}
 
.term-of-serivce-text107 {
  display: inline-block;
}
 
.term-of-serivce-fragment18 {
  display: contents;
}
 
.term-of-serivce-text108 {
  display: inline-block;
}
 
.term-of-serivce-fragment19 {
  display: contents;
}
 
.term-of-serivce-text109 {
  display: inline-block;
}
 
.term-of-serivce-fragment20 {
  display: contents;
}
 
.term-of-serivce-text110 {
  display: inline-block;
}
 
.term-of-serivce-fragment21 {
  display: contents;
}
 
.term-of-serivce-text111 {
  display: inline-block;
}
 
.term-of-serivce-fragment22 {
  display: contents;
}
 
.term-of-serivce-text112 {
  display: inline-block;
}
 
.term-of-serivce-fragment23 {
  display: contents;
}
 
.term-of-serivce-text113 {
  display: inline-block;
}
 
.term-of-serivce-fragment24 {
  display: contents;
}
 
.term-of-serivce-text114 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text115 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text119 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text127 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text128 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text133 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text143 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text147 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text153 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text157 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text161 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text165 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text169 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text173 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text174 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text181 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-fragment25 {
  display: contents;
}
 
.term-of-serivce-text187 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text188 {
  font-weight: 700;
}
 
.term-of-serivce-text200 {
  font-weight: 700;
}
 
.term-of-serivce-text212 {
  font-weight: 700;
}
 
.term-of-serivce-text218 {
  font-weight: 700;
}
 
.term-of-serivce-text228 {
  font-weight: 700;
}
 
.term-of-serivce-text240 {
  font-weight: 700;
}
 
.term-of-serivce-text244 {
  font-weight: 700;
}
 
.term-of-serivce-text245 {
  font-weight: 700;
}
 
.term-of-serivce-text254 {
  font-weight: 700;
}
 
.term-of-serivce-text261 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-text264 {
  font-weight: 700;
}
 
.term-of-serivce-fragment26 {
  display: contents;
}
 
.term-of-serivce-text267 {
  display: inline-block;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 800;
}
 
.term-of-serivce-fragment27 {
  display: contents;
}
 
.term-of-serivce-text268 {
  display: inline-block;
  font-size: 36px;
  font-family: "Open Sans";
}
</style>
