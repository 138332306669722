<template>
  <div class="features21-layout302 thq-section-padding">
    <div class="features21-max-width thq-section-max-width">
      <div class="features21-section-title thq-flex-column">
        <h2 class="thq-heading-2">
          <slot name="sectionTitle">
            <!--Default content for sectionTitle-->
            <div class="features21-fragment11">
              <span class="features21-text13">Discover the Key Features</span>
            </div>
          </slot>
        </h2>
        <p class="thq-body-large">
          <slot name="sectionDescription">
            <!--Default content for sectionDescription-->
            <div class="features21-fragment15">
              <span class="features21-text17">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique. Duis cursus,
                mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
                libero vitae erat. Suspendisse varius enim in eros elementum
                tristique. Duis cursus, mi quis viverra ornare, eros dolor
                interdum nulla.
              </span>
            </div>
          </slot>
        </p>
      </div>
      <div class="features21-content1">
        <div class="thq-grid-5">
          <div class="features21-feature1 thq-flex-column">
            <img
              :alt="feature1ImageAlt"
              :src="feature1ImageSrc"
              class="thq-team-image-round"
            />
            <div class="thq-flex-column-left">
              <h3 class="thq-heading-3">
                <slot name="feature1Title">
                  <!--Default content for feature1Title-->
                  <div class="features21-fragment16">
                    <span class="features21-text18">
                      Engaging Content for Various Topics
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small">
                <slot name="feature1Description">
                  <!--Default content for feature1Description-->
                  <div class="features21-fragment19">
                    <span class="features21-text21">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="features21-feature2 thq-flex-column">
            <img
              :alt="feature2ImageAlt"
              :src="feature2ImageSrc"
              class="thq-team-image-round"
            />
            <div class="thq-flex-column-left">
              <h3 class="thq-heading-3">
                <slot name="feature2Title">
                  <!--Default content for feature2Title-->
                  <div class="features21-fragment13">
                    <span class="features21-text15">
                      Engaging Content for Various Topics
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small">
                <slot name="feature2Description">
                  <!--Default content for feature2Description-->
                  <div class="features21-fragment17">
                    <span class="features21-text19">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="features21-feature3 thq-flex-column">
            <img
              :alt="feature3ImageAlt"
              :src="feature3ImageSrc"
              class="thq-team-image-round"
            />
            <div class="thq-flex-column-left">
              <h3 class="thq-heading-3">
                <slot name="feature3Title">
                  <!--Default content for feature3Title-->
                  <div class="features21-fragment22">
                    <span class="features21-text24">
                      Engaging Content for Various Topics
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small">
                <slot name="feature3Description">
                  <!--Default content for feature3Description-->
                  <div class="features21-fragment12">
                    <span class="features21-text14">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="features21-feature4 thq-flex-column">
            <img
              :alt="feature4ImageAlt"
              :src="feature4ImageSrc"
              class="thq-team-image-round"
            />
            <div class="thq-flex-column-left">
              <h3 class="thq-heading-3">
                <slot name="feature4Title">
                  <!--Default content for feature4Title-->
                  <div class="features21-fragment10">
                    <span class="features21-text12">
                      Engaging Content for Various Topics
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small">
                <slot name="feature4Description">
                  <!--Default content for feature4Description-->
                  <div class="features21-fragment23">
                    <span class="features21-text25">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="features21-feature5 thq-flex-column">
            <img
              :alt="feature5ImageAlt"
              :src="feature5ImageSrc"
              class="thq-team-image-round"
            />
            <div class="thq-flex-column-left">
              <h3 class="thq-heading-3">
                <slot name="feature5Title">
                  <!--Default content for feature5Title-->
                  <div class="features21-fragment21">
                    <span class="features21-text23">
                      Engaging Content for Various Topics
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small">
                <slot name="feature5Description">
                  <!--Default content for feature5Description-->
                  <div class="features21-fragment18">
                    <span class="features21-text20">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Suspendisse varius enim in eros elementum tristique.
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
        </div>
        <div class="features21-actions">
          <button class="thq-button-filled features21-button1">
            <span class="thq-body-small">
              <slot name="mainAction">
                <!--Default content for mainAction-->
                <div class="features21-fragment20">
                  <span class="features21-text22">Main action</span>
                </div>
              </slot>
            </span>
          </button>
          <button class="thq-button-outline features21-button2">
            <span class="thq-body-small">
              <slot name="secondaryAction">
                <!--Default content for secondaryAction-->
                <div class="features21-fragment14">
                  <span class="features21-text16">Secondary action</span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Features21',
  props: {
    feature4ImageAlt: {
      type: String,
      default: 'image',
    },
    feature3ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
    feature1ImageAlt: {
      type: String,
      default: 'image',
    },
    feature3ImageAlt: {
      type: String,
      default: 'image',
    },
    feature5ImageAlt: {
      type: String,
      default: 'image',
    },
    feature2ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
    feature4ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
    feature5ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
    feature2ImageAlt: {
      type: String,
      default: 'image',
    },
    feature1ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
  },
}
</script>

<style scoped>
.features21-layout302 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
 
.features21-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.features21-section-title {
  align-items: flex-start;
}
 
.features21-content1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.features21-feature1 {
  align-items: flex-start;
}
 
.features21-feature2 {
  align-items: flex-start;
}
 
.features21-feature3 {
  align-items: flex-start;
}
 
.features21-feature4 {
  align-items: flex-start;
}
 
.features21-feature5 {
  align-items: flex-start;
}
 
.features21-actions {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
 
.features21-fragment10 {
  display: contents;
}
 
.features21-text12 {
  display: inline-block;
}
 
.features21-fragment11 {
  display: contents;
}
 
.features21-text13 {
  display: inline-block;
}
 
.features21-fragment12 {
  display: contents;
}
 
.features21-text14 {
  display: inline-block;
}
 
.features21-fragment13 {
  display: contents;
}
 
.features21-text15 {
  display: inline-block;
}
 
.features21-fragment14 {
  display: contents;
}
 
.features21-text16 {
  display: inline-block;
}
 
.features21-fragment15 {
  display: contents;
}
 
.features21-text17 {
  display: inline-block;
}
 
.features21-fragment16 {
  display: contents;
}
 
.features21-text18 {
  display: inline-block;
}
 
.features21-fragment17 {
  display: contents;
}
 
.features21-text19 {
  display: inline-block;
}
 
.features21-fragment18 {
  display: contents;
}
 
.features21-text20 {
  display: inline-block;
}
 
.features21-fragment19 {
  display: contents;
}
 
.features21-text21 {
  display: inline-block;
}
 
.features21-fragment20 {
  display: contents;
}
 
.features21-text22 {
  display: inline-block;
}
 
.features21-fragment21 {
  display: contents;
}
 
.features21-text23 {
  display: inline-block;
}
 
.features21-fragment22 {
  display: contents;
}
 
.features21-text24 {
  display: inline-block;
}
 
.features21-fragment23 {
  display: contents;
}
 
.features21-text25 {
  display: inline-block;
}
 
@media(max-width: 479px) {
  .features21-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .features21-actions {
    width: 100%;
    flex-direction: column;
  }
  .features21-button1 {
    width: 100%;
  }
  .features21-button2 {
    width: 100%;
  }
}
</style>
