<template>
  <div
    class="features5-layout223 thq-section-padding"
    v-bind:class="rootClassName"
  >
    <div class="features5-max-width thq-flex-row thq-section-max-width">
      <div class="features5-container thq-flex-column">
        <img
          :alt="featureImageAlt"
          :src="featureImageSrc"
          class="thq-img-ratio-4-3"
        />
      </div>
      <div class="thq-flex-column">
        <div class="features5-list thq-flex-column">
          <div class="thq-flex-row features5-list-item1">
            <img
              :alt="feature2ImageAlt"
              :src="feature2ImageSrc"
              class="thq-team-image-round"
            />
            <div class="features5-content2 thq-flex-column">
              <h3 class="thq-heading-3 features5-title2">
                <slot name="feature2Description1">
                  <!--Default content for feature2Description1-->
                  <div class="features5-fragment5">
                    <span class="features5-text5">
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small features5-description2">
                <slot name="feature2Description">
                  <!--Default content for feature2Description-->
                  <div class="features5-fragment4">
                    <span class="features5-text4">
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="thq-flex-row features5-list-item2">
            <img
              :alt="feature3ImageAlt"
              :src="feature3ImageSrc"
              class="thq-team-image-round"
            />
            <div class="features5-content3 thq-flex-column">
              <h3 class="thq-heading-3 features5-title3">
                <slot name="feature3Title">
                  <!--Default content for feature3Title-->
                  <div class="features5-fragment3">
                    <span class="features5-text3">
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small features5-description3">
                <slot name="feature3Description">
                  <!--Default content for feature3Description-->
                  <div class="features5-fragment8">
                    <span class="features5-text8">
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
          <div class="thq-flex-row">
            <img
              :alt="feature1ImageAlt"
              :src="feature1ImageSrc"
              class="thq-team-image-round"
            />
            <div class="features5-content4 thq-flex-column">
              <h3 class="thq-heading-3 features5-title1">
                <slot name="feature1Title">
                  <!--Default content for feature1Title-->
                  <div class="features5-fragment2">
                    <span class="features5-text2">
                      Discover the Power of Actions
                    </span>
                  </div>
                </slot>
              </h3>
              <span class="thq-body-small features5-description1">
                <slot name="feature1Description">
                  <!--Default content for feature1Description-->
                  <div class="features5-fragment6">
                    <span class="features5-text6">
                    </span>
                  </div>
                </slot>
              </span>
            </div>
          </div>
        </div>
        <div class="thq-flex-row features5-actions">
          <button class="thq-button-filled features5-button1">
            <span class="thq-body-small">
              <slot name="mainAction">
                <!--Default content for mainAction-->
                <div class="features5-fragment1">
                  <span class="features5-text1">Main action</span>
                </div>
              </slot>
            </span>
          </button>
          <button class="thq-button-outline features5-button2">
            <span class="thq-body-small">
              <slot name="secondaryAction">
                <!--Default content for secondaryAction-->
                <div class="features5-fragment7">
                  <span class="features5-text7">Secondary action</span>
                </div>
              </slot>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Features5',
  props: {
    feature3ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
    featureImageSrc: {
      type: String,
      default:
        'https://images.unsplash.com/photo-1524169113253-c6ba17f68498?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDExMnx8YWJzdHJhY3R8ZW58MHx8fHwxNzEwOTMzOTc2fDA&ixlib=rb-4.0.3&w=1400',
    },
    featureImageAlt: {
      type: String,
      default: 'PlaceholderImage1314',
    },
    feature1ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
    feature1ImageAlt: {
      type: String,
      default: 'image',
    },
    rootClassName: String,
    feature3ImageAlt: {
      type: String,
      default: 'image',
    },
    feature2ImageAlt: {
      type: String,
      default: 'image',
    },
    feature2ImageSrc: {
      type: String,
      default: '/budgetin_logo-1500h.png',
    },
  },
}
</script>

<style scoped>
.features5-layout223 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
}
 
.features5-max-width {
  gap: var(--dl-space-space-threeunits);
}
 
.features5-container {
  align-items: center;
  justify-content: center;
}
 
.features5-list {
  align-self: stretch;
  align-items: flex-start;
}
 
.features5-content2 {
  gap: var(--dl-space-space-unit);
  align-items: flex-start;
}
 
.features5-content3 {
  gap: var(--dl-space-space-unit);
  align-items: flex-start;
}
 
.features5-content4 {
  gap: var(--dl-space-space-unit);
  align-items: flex-start;
}
 
.features5-fragment1 {
  display: contents;
}
 
.features5-text1 {
  display: inline-block;
}
 
.features5-fragment2 {
  display: contents;
}
 
.features5-text2 {
  display: inline-block;
}
 
.features5-fragment3 {
  display: contents;
}
 
.features5-text3 {
  display: inline-block;
}
 
.features5-fragment4 {
  display: contents;
}
 
.features5-text4 {
  display: inline-block;
}
 
.features5-fragment5 {
  display: contents;
}
 
.features5-text5 {
  display: inline-block;
}
 
.features5-fragment6 {
  display: contents;
}
 
.features5-text6 {
  display: inline-block;
}
 
.features5-fragment7 {
  display: contents;
}
 
.features5-text7 {
  display: inline-block;
}
 
.features5-fragment8 {
  display: contents;
}
 
.features5-text8 {
  display: inline-block;
}
 
.features5root-class-name {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
}
 
.features5root-class-name1 {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
}
 
@media(max-width: 991px) {
  .features5-layout223 {
    flex-direction: column;
  }
  .features5-max-width {
    flex-direction: column;
  }
  .features5-title2 {
    text-align: center;
  }
  .features5-description2 {
    text-align: left;
  }
  .features5-content3 {
    align-items: flex-start;
  }
  .features5-title3 {
    text-align: center;
  }
  .features5-description3 {
    text-align: left;
  }
  .features5-content4 {
    align-items: flex-start;
  }
  .features5-title1 {
    text-align: center;
  }
  .features5-description1 {
    text-align: left;
  }
  .features5-actions {
    align-items: center;
  }
  .features5-button1 {
    width: 100%;
  }
  .features5-button2 {
    width: 100%;
  }
}
 
@media(max-width: 479px) {
  .features5-list-item1 {
    gap: var(--dl-space-space-unit);
  }
  .features5-list-item2 {
    gap: var(--dl-space-space-unit);
  }
  .features5-actions {
    width: 100%;
    flex-wrap: wrap;
    margin-top: var(--dl-space-space-unit);
  }
  .features5-button1 {
    width: 100%;
  }
  .features5-button2 {
    width: 100%;
  }
}
</style>
