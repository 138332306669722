import { createWebHistory, createRouter } from 'vue-router'

import TermOfService from './views/term-of-service'
import PrivacyPolicy from './views/privacy-policy'
import UserDataDeletion from './views/user-data-deletion'
import FAQ from './views/frequent-ask-quetion'
import Berlangganan from './views/berlangganan'
import Home from './views/home'
import NotFound from './views/not-found'
import ExportData from './views/export-data'
import './style.css'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home,
  },
  {
    name: 'Berlangganan',
    path: '/berlangganan',
    component: Berlangganan,
  },
  {
    name: 'Term-of-Service',
    path: '/term-of-service',
    component: TermOfService,
  },
  {
    name: 'Privacy-Policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    name: 'User-Data-Deletion',
    path: '/delete-data',
    component: UserDataDeletion,
  },
  {
    name: 'FAQ',
    path: '/faq',
    component: FAQ,
  },
  {
    name: 'ExportData',
    path: '/exportdata',
    component: ExportData,
  },
  {
    name: '404 - Not Found',
    path: '/**',
    component: NotFound,
    fallback: true,
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
