<template>
  <div class="home-container">
    <app-navbar4>
    </app-navbar4>
    <app-features5
      rootClassName="features5root-class-name"
      featureImageSrc="/mock-up-chat-1.png"
      feature1ImageSrc="/customer-service-200h.png"
      feature2ImageSrc="/chat_icon.png"
      feature3ImageSrc="/checklist icon.png"
    >
      <template v-slot:mainAction>
        <div class="home-fragment16">
          <button class="home-text16" @click="openWhatsApp">
          Coba Sekarang
          </button>
        </div>
      </template>
      <template v-slot:feature1Title>
        <div class="home-fragment17">
          <span class="home-text17">24/7 Services</span>
        </div>
      </template>
      <template v-slot:feature3Title>
        <div class="home-fragment18">
          <span class="home-text18">Keep Your Budget in Check</span>
        </div>
      </template>
      <template v-slot:secondaryAction>
        <div class="home-fragment19">
          <RouterLink class="home-text19" to="Term-of-Service" >Term of Service</RouterLink>
        </div>
      </template>
      <template v-slot:feature1Description>
        <div class="home-fragment20">
          <span class="home-text20">
            Available 24/7 to assist you anytime, anywhere.
          </span>
        </div>
      </template>
      <template v-slot:feature2Description>
        <div class="home-fragment21">
          <span class="home-text21">
            Smart & Convenient Budget Journaling
          </span>
        </div>
      </template>
      <template v-slot:feature3Description>
        <div class="home-fragment22">
          <span class="home-text22">
            A simple and practical way to log your daily expenses &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>
      </template>
      <template v-slot:feature2Description1>
        <div class="home-fragment23">
          <span class="home-text23">Simple as a Chat</span>
        </div>
      </template>
    </app-features5>
    <AppFooter>
    </AppFooter>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures5 from '../components/features5'
import { RouterLink } from 'vue-router';
import AppFooter from '../components/AppFooter.vue'

export default {
  name: 'Home',
  props: {},
  
  components: {
    AppNavbar4,
    AppFeatures5,
    AppFooter
  },
  metaInfo: {
    title: 'Budgetin',
    meta: [
      {
        property: 'og:title',
        content: 'Bugetin',
      },
    ],
  },  
  data() {
    return {
      whatsappUrl: "https://wa.me/message/RSVN7UVO37DKF1", // WhatsApp link
    };
  },

  methods: {
    openWhatsApp() {
      console.log("Opening WhatsApp:", this.whatsappUrl); // Debugging log
      window.open(this.whatsappUrl, "_blank"); // Open WhatsApp in a new tab
    },
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.home-fragment10 {
  display: contents;
}
 
.home-text10 {
  display: inline-block;
}
 
.home-fragment11 {
  display: contents;
}
 
.home-text11 {
  display: inline-block;
}
 
.home-fragment12 {
  display: contents;
}
 
.home-text12 {
  display: inline-block;
}
 
.home-fragment13 {
  display: contents;
}
 
.home-text13 {
  display: inline-block;
}
 
.home-fragment14 {
  display: contents;
}
 
.home-text14 {
  display: inline-block;
}
 
.home-fragment15 {
  display: contents;
}
 
.home-text15 {
  display: inline-block;
}
 
.home-fragment16 {
  display: contents;
}
 
.home-text16 {
  display: inline-block;
}
 
.home-fragment17 {
  display: contents;
}
 
.home-text17 {
  display: inline-block;
}
 
.home-fragment18 {
  display: contents;
}
 
.home-text18 {
  text-align: left;
  display: inline-block;
}
 
.home-fragment19 {
  display: contents;
}
 
.home-text19 {
  display: inline-block;
}
 
.home-fragment20 {
  display: contents;
}
 
.home-text20 {
  display: inline-block;
}
 
.home-fragment21 {
  display: contents;
}
 
.home-text21 {
  display: inline-block;
}
 
.home-fragment22 {
  display: contents;
}
 
.home-text22 {
  display: inline-block;
}
 
.home-fragment23 {
  display: contents;
}
 
.home-text23 {
  display: inline-block;
}
</style>
