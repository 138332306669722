<template>
  <div class="term-of-serivce-container">
    <app-navbar4>
    </app-navbar4>
    <content-list1>
      
      <template v-slot:heading1>
        <div class="term-of-serivce-fragment26">
          <span class="term-of-serivce-text267">Frequent Asked Question</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="term-of-serivce-fragment24">
          <span class="term-of-serivce-text114">
            <span class="term-of-serivce-text115">Acceptance of Terms</span>
            <br />
            <span>
              By using Budgetin, you acknowledge that you have read, understood, and agreed to these Terms and the Privacy Policy.
              You also accept and comply with WhatsApp's Terms of Service and Meta's Privacy Policy when using our services through the WhatsApp platform.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">1. <i>What is Budgetin?</i> <br/>Apa itu Budgetin?</span>
            <br />
            <span>
             <b> <i>Budgetin is a WhatsApp chatbot to help users journal and monitor daily spending.</i></b>

              <br />
              Budgetin adalah chatbot WhatsApp untuk mencatat dan mengelola pengeluaran harian.

            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">2.	<i>How does Budgetin work?</i> <br/>Bagaimana cara kerja Budgetin?</span>
            <br />
            <span><b><i>Simply chat with Budgetin on WhatsApp to journal your budget. Your data is recorded only with your consent, and you are free to stop anytime.</i></b> 
           <br/> Cukup chat dengan Budgetin di WhatsApp untuk mencatat budgetmu. Semua data dicatat hanya dengan persetujuanmu, dan kamu bebas berhenti kapan saja.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">3.	<i>Is Budgetin free?</i> <br/>Apakah Budgetin gratis?</span>
            <br />
            <span>
              <b><i>No, Budgetin is a paid service with a monthly subscription of IDR 39,900.</i></b>

              <br/>
            
              Tidak, Budgetin adalah layanan berbayar dengan biaya langganan Rp 39.900 per bulan.

            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">4.	<i>Does Budgetin give financial advice?</i> <br/>Apakah Budgetin memberi saran keuangan?</span>
            <br />
            <span>

              <b><i>No, Budgetin only helps record expenses and does not provide financial advice.</i></b>
              <br/>

              Tidak, Budgetin hanya membantu mencatat pengeluaran dan tidak memberikan saran keuangan.


            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">5.	<i>Is my data secure?</i> <br/>Apakah data saya aman?</span>
            <br />
            <span>
              <b><i>Yes, all user data is encrypted and not shared or sold.</i></b>
              <br/>

              Ya, semua data pengguna dienkripsi dan tidak dibagikan atau dijual.

            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">6.  <i>Can I delete my data?</i> <br/>Bisakah saya menghapus data saya?</span>
            <br />
            <span>
              <b><i>Yes, you can request data deletion at budget-in.com/delete-data.</i></b>
              <br/>

              Ya, kamu bisa meminta penghapusan data di budget-in.com/delete-data.

            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">7.	<i>Does Budgetin share data with third parties?</i> <br/>  Apakah Budgetin membagikan data ke pihak ketiga?</span>
            <br />
            <span>
             <b> <i>No, Budgetin does not sell or share data, but Meta may collect data per their policy.</i></b> <br/>

              Tidak, Budgetin tidak menjual atau membagikan data, namun Meta dapat mengumpulkan data sesuai kebijakan mereka.

            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">8.	<i>Where can I get support?</i><br/> Di mana saya bisa mendapatkan bantuan?</span>
            <br />
            <span>
              <b><i>Contact us via WhatsApp +62 81387759975 or email admin@budget-in.com</i></b> <br/>

              Hubungi kami via WhatsApp atau kunjungi budget-in.com atau email admin@budget-in.com

            </span>
            <br />
            <br />
            <span class="term-of-serivce-text261">Contact Us</span>
            <br />
            <span>
              If you have further questions, please contact us
              at
              <span v-html="raw16dr"></span>
            </span>
            <span class="term-of-serivce-text264">admin@budget-in.com or WhatsApp +6281387759975</span>
          </span>
        </div>
      </template>
    </content-list1>
    <AppFooter>

    </AppFooter>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures5 from '../components/features5'
import ContentList1 from '../components/content-list1'
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'FAQ',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures5,
    ContentList1,
    AppFooter
  },
  data() {
    return {
      raw16dr: ' ',
    }
  },
  metaInfo: {
    title: 'Budgetin - FAQ',
    meta: [
      {
        property: 'og:title',
        content: 'FAQ - Budgetin',
      },
    ],
  },
}
</script>

<style scoped>
.term-of-serivce-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.term-of-serivce-fragment10 {
  display: contents;
}
 
.term-of-serivce-text100 {
  display: inline-block;
}
 
.term-of-serivce-fragment11 {
  display: contents;
}
 
.term-of-serivce-text101 {
  display: inline-block;
}
 
.term-of-serivce-fragment12 {
  display: contents;
}
 
.term-of-serivce-text102 {
  display: inline-block;
}
 
.term-of-serivce-fragment13 {
  display: contents;
}
 
.term-of-serivce-text103 {
  display: inline-block;
}
 
.term-of-serivce-fragment14 {
  display: contents;
}
 
.term-of-serivce-text104 {
  display: inline-block;
}
 
.term-of-serivce-fragment15 {
  display: contents;
}
 
.term-of-serivce-text105 {
  display: inline-block;
}
 
.term-of-serivce-fragment16 {
  display: contents;
}
 
.term-of-serivce-text106 {
  display: inline-block;
}
 
.term-of-serivce-fragment17 {
  display: contents;
}
 
.term-of-serivce-text107 {
  display: inline-block;
}
 
.term-of-serivce-fragment18 {
  display: contents;
}
 
.term-of-serivce-text108 {
  display: inline-block;
}
 
.term-of-serivce-fragment19 {
  display: contents;
}
 
.term-of-serivce-text109 {
  display: inline-block;
}
 
.term-of-serivce-fragment20 {
  display: contents;
}
 
.term-of-serivce-text110 {
  display: inline-block;
}
 
.term-of-serivce-fragment21 {
  display: contents;
}
 
.term-of-serivce-text111 {
  display: inline-block;
}
 
.term-of-serivce-fragment22 {
  display: contents;
}
 
.term-of-serivce-text112 {
  display: inline-block;
}
 
.term-of-serivce-fragment23 {
  display: contents;
}
 
.term-of-serivce-text113 {
  display: inline-block;
}
 
.term-of-serivce-fragment24 {
  display: contents;
}
 
.term-of-serivce-text114 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text115 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text119 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text127 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text128 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text133 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text143 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text147 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text153 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text157 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text161 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text165 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text169 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text173 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text174 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text181 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-fragment25 {
  display: contents;
}
 
.term-of-serivce-text187 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text188 {
  font-weight: 700;
}
 
.term-of-serivce-text200 {
  font-weight: 700;
}
 
.term-of-serivce-text212 {
  font-weight: 700;
}
 
.term-of-serivce-text218 {
  font-weight: 700;
}
 
.term-of-serivce-text228 {
  font-weight: 700;
}
 
.term-of-serivce-text240 {
  font-weight: 700;
}
 
.term-of-serivce-text244 {
  font-weight: 700;
}
 
.term-of-serivce-text245 {
  font-weight: 700;
}
 
.term-of-serivce-text254 {
  font-weight: 700;
}
 
.term-of-serivce-text261 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-text264 {
  font-weight: 700;
}
 
.term-of-serivce-fragment26 {
  display: contents;
}
 
.term-of-serivce-text267 {
  display: inline-block;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 800;
}
 
.term-of-serivce-fragment27 {
  display: contents;
}
 
.term-of-serivce-text268 {
  display: inline-block;
  font-size: 36px;
  font-family: "Open Sans";
}
</style>
