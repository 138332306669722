<template>
  <div class="term-of-serivce-container">
    <app-navbar4>
    </app-navbar4>
    <content-list1>
      
      <template v-slot:heading1>
        <div class="term-of-serivce-fragment26">
          <span class="term-of-serivce-text267">User Data Deletion Request</span>
        </div>
      </template>
      <template v-slot:content1>
        <div class="term-of-serivce-fragment24">
          <span class="term-of-serivce-text114">
            <span class="term-of-serivce-text115">Meta Compliance: How to Delete Your Data from Budgetin</span>
            <br />
            <span>
              If you wish to delete all your data from Budgetin, follow the steps below. 
              This process is designed to ensure that your request is intentional and complies with our Privacy Policy and Terms of Service.
            </span>
            <br />
            <br />
            <br />
            <span class="term-of-serivce-text115">Step-by-Step Guide to Delete Your Data</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">1. Start the Deletion Process</span>
            <br />
            <span>
              •	Open the Budgetin chatbot on WhatsApp.
            </span>            
            <br />
            <span>
              •	Type: "Erase all my data" in the chat.
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">2. Confirm Your Request</span>
            <br />
            <span>• The chatbot will ask for confirmation to ensure this request is intentional.</span>
            <br />
            <span>• To proceed, you must type a confirmation statement.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">3. Provide Final Confirmation</span>
            <br />
            <span>•	Respond with the following statement:
            </span>
            <br />
            <span>
              "I agree to delete all my data consciously and in compliance with the Privacy Policy and Terms of Service."
            </span>
            <br />
            <br />
            <span class="term-of-serivce-text115">4. Data Deletion Execution </span>
            <br />
            <span>•	Respond with the following statement: </span>
            <br />
            <span>•	This action is irreversible.</span>
            <br />
            <br />
            <span class="term-of-serivce-text115">5. Receive Confirmation</span>
            <br />
            <span>•	You will get a final message confirming that your data has been permanently deleted.</span>
            <br />
            <span>•	This action is irreversible.</span>
            <br />
            <br />
            <div class="horizontal_line"></div>
            <span class="term-of-serivce-text115">Important Notes:</span>
            <br />
            <span>
              •	This action <b>cannot be undone.</b> Once deleted, your data is permanently removed.
            </span>
            <br />
            <span>
              •	Budgetin does <b>not retain or restore deleted data.</b>
            </span>
            <br />
            <span>
              •	Ensure you understand the impact of this action before proceeding.
            </span>
            <br />
            <span>
              If you have any issues with the deletion process, please contact our support team <b>admin@budget-in.com</b>
            </span>
          </span>
        </div>
      </template>
    </content-list1>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppNavbar4 from '../components/navbar4'
import AppFeatures5 from '../components/features5'
import ContentList1 from '../components/content-list1'
import AppFooter from '../components/AppFooter.vue';

export default {
  name: 'UserDataDeletion',
  props: {},
  components: {
    AppNavbar4,
    AppFeatures5,
    ContentList1,
    AppFooter
  },
  data() {
    return {
      raw16dr: ' ',
    }
  },
  metaInfo: {
    title: 'Budgetin - Delete User Data',
    meta: [
      {
        property: 'og:title',
        content: 'User-Data-Deletion - Budgetin',
      },
    ],
  },
}
</script>

<style scoped>
.term-of-serivce-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
 
.term-of-serivce-fragment10 {
  display: contents;
}
 
.term-of-serivce-text100 {
  display: inline-block;
}
 
.term-of-serivce-fragment11 {
  display: contents;
}
 
.term-of-serivce-text101 {
  display: inline-block;
}
 
.term-of-serivce-fragment12 {
  display: contents;
}
 
.term-of-serivce-text102 {
  display: inline-block;
}
 
.term-of-serivce-fragment13 {
  display: contents;
}
 
.term-of-serivce-text103 {
  display: inline-block;
}
 
.term-of-serivce-fragment14 {
  display: contents;
}
 
.term-of-serivce-text104 {
  display: inline-block;
}
 
.term-of-serivce-fragment15 {
  display: contents;
}
 
.term-of-serivce-text105 {
  display: inline-block;
}
 
.term-of-serivce-fragment16 {
  display: contents;
}
 
.term-of-serivce-text106 {
  display: inline-block;
}
 
.term-of-serivce-fragment17 {
  display: contents;
}
 
.term-of-serivce-text107 {
  display: inline-block;
}
 
.term-of-serivce-fragment18 {
  display: contents;
}
 
.term-of-serivce-text108 {
  display: inline-block;
}
 
.term-of-serivce-fragment19 {
  display: contents;
}
 
.term-of-serivce-text109 {
  display: inline-block;
}
 
.term-of-serivce-fragment20 {
  display: contents;
}
 
.term-of-serivce-text110 {
  display: inline-block;
}
 
.term-of-serivce-fragment21 {
  display: contents;
}
 
.term-of-serivce-text111 {
  display: inline-block;
}
 
.term-of-serivce-fragment22 {
  display: contents;
}
 
.term-of-serivce-text112 {
  display: inline-block;
}
 
.term-of-serivce-fragment23 {
  display: contents;
}
 
.term-of-serivce-text113 {
  display: inline-block;
}
 
.term-of-serivce-fragment24 {
  display: contents;
}
 
.term-of-serivce-text114 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text115 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text119 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text127 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text128 {
  font-style: italic;
  font-weight: 600;
}
 
.term-of-serivce-text133 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text143 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text147 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text153 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text157 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text161 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text165 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text169 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text173 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text174 {
  font-style: normal;
  font-weight: 800;
}
 
.term-of-serivce-text181 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-fragment25 {
  display: contents;
}
 
.term-of-serivce-text187 {
  display: inline-block;
  font-family: "Open Sans";
}
 
.term-of-serivce-text188 {
  font-weight: 700;
}
 
.term-of-serivce-text200 {
  font-weight: 700;
}
 
.term-of-serivce-text212 {
  font-weight: 700;
}
 
.term-of-serivce-text218 {
  font-weight: 700;
}
 
.term-of-serivce-text228 {
  font-weight: 700;
}
 
.term-of-serivce-text240 {
  font-weight: 700;
}
 
.term-of-serivce-text244 {
  font-weight: 700;
}
 
.term-of-serivce-text245 {
  font-weight: 700;
}
 
.term-of-serivce-text254 {
  font-weight: 700;
}
 
.term-of-serivce-text261 {
  font-size: 26px;
  font-weight: 700;
}
 
.term-of-serivce-text264 {
  font-weight: 700;
}
 
.term-of-serivce-fragment26 {
  display: contents;
}
 
.term-of-serivce-text267 {
  display: inline-block;
  font-style: normal;
  font-family: "Open Sans";
  font-weight: 800;
}
 
.term-of-serivce-fragment27 {
  display: contents;
}
 
.term-of-serivce-text268 {
  display: inline-block;
  font-size: 36px;
  font-family: "Open Sans";
}

.horizontal_line {
            width: 100%;
            height: 5px;
            border-top: 1px solid gray;
            line-height: 80%;
        }

.line {
    border-bottom: 5px solid red;
    margin-top: 5px;
    width: 90%;
}
</style>
