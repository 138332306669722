
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App);

app.use(router);
app.use(VueReCaptcha, { siteKey: '6LeNsegqAAAAAH8tlsua-5MQh5MsX7Eb3DZ28g8T'});
app.mount('#app');